import Router from "./Router";
import "./app.scss";
import "./material.scss";

const App = () => {
  return (
    <div className="main">
      <Router />
    </div>
  );
};

export default App;
